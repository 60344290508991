import React, { useState } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

import SideMenu from "./components/SideMenu";
import './App.css';
const Dashboard = () => {
    return <h1>Dashboard</h1>
}

const Content = () => {
    return <h1>Content</h1>
}

const Courses = () => {
    return <h1>Content/Courses</h1>
}

const Videos = () => {
    return <h1>Content/Videos</h1>
}

const Design = () => {
    return <h1>Design</h1>
}



function App() {
    const [inactive, setInactive] = useState(false);
    return (
        <div className="App">
            <Router>
                <SideMenu onCollapse={(inactive) => {
                    setInactive(inactive);
                }} />
                <div className={`maincontent ${inactive ? 'inactive' : ''}`}>
                    <Routes>
                        <Route path={'/'} element={<Dashboard />} />
                        <Route path={'/content/'} element={<Content />} />
                        <Route path={'/content/courses'} element={<Courses />} />
                        <Route path={'/content/videos'} element={<Videos />} />
                        <Route path={'/design'} element={<Design />} />

                    </Routes>
                </div>

            </Router>

        </div>

    );
}

export default App;